.layoutRoot {
    background: #EAEEF2;
}

.headerStyle {
    box-shadow: 0px 2px 6px 0px rgba(32, 32, 34, 0.08);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*line-height: 40px;*/
    padding: 0 12px;
}

.siderLogo{
    cursor: pointer;
    text-align: center;
    height: 40px;
    line-height: 40px;
}

.nameTitle {
    font-weight: 800;
}

.headerTitle {
    font-size: 14px;
    color: #666666;

}
