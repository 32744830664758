/* cover ant design theme here if necessary */
.ant-layout .label_left .form-label{
    text-align: left;
}
.ant-table-content .ant-table-placeholder{
    /*border-bottom: transparent;*/
    padding: 0px;
}
#root .table-form.ant-table-wrapper{
    border: 1px solid rgb(232, 232, 232);
}
#root .table-form .ant-table-row td{border-bottom: transparent}
#root .table-form .ant-table-body {overflow-y: auto!important;overflow-x: auto!important;}
#root .table-form .ant-table-body-inner {overflow-y: auto!important;overflow-x: auto!important;}
/*#root .ant-table-wrapper .ant-table-thead > tr > th{padding:6px 8px;color:#666}*/
/*#root .ant-table-wrapper .ant-table-tbody > tr > td{padding:6px 8px}*/
